import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Slider from "@mui/material/Slider";
import { Box } from "@mui/material";

const marksCompact = [
  { value: 360, label: "360px" },
  { value: 540, label: "540px" },
];

const marksFull = [
  { value: 360, label: "360px" },
  { value: 540, label: "540px" },
  { value: 720, label: "720px" },
  { value: 900, label: "900px" },
  { value: 1080, label: "1080px" },
];

function getClosestLowerMark(value, marks) {
  return marks.reduce((prev, curr) => (curr.value <= value ? curr : prev), marks[0]).value;
}

export default function CustomSlider({ value, onChange, compact }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      // If the current value is greater than the window width, then update it
      if (value > window.innerWidth) {
        const closestMark = getClosestLowerMark(window.innerWidth, compact ? marksCompact : marksFull);
        onChange(null, closestMark);
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function:
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [value, onChange, compact]);

  const availableMarks = compact ? marksCompact : marksFull.filter((mark) => mark.value <= windowWidth);

  return (
    <Box sx={{ width: "75%", display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Slider
        aria-labelledby="discrete-slider-always"
        step={null}
        valueLabelDisplay="off"
        marks={availableMarks}
        min={360}
        max={availableMarks.length > 0 ? availableMarks[availableMarks.length - 1].value : 360} // check if availableMarks is not empty
        value={value}
        onChange={onChange}
      />
    </Box>
  );
}

CustomSlider.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  compact: PropTypes.bool.isRequired,
};
