import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import CustomToggle from "./CustomToggle";
import CustomSlider from "./CustomSlider";
import FormSelector from "./FormSelector";

const ControlPanel = ({
  componentWidth,
  setComponentWidth,
  setDesktop,
  compact,
  setCompact,
  multiPart,
  setMultiPart,
  backgroundColor,
  setBackgroundColor,
  containerPadding,
  setContainerPadding,
  service,
  setService,
}) => (
  <Grid
    container
    spacing={3}
    alignItems="center"
    justify="space-between"
    sx={{
      minHeight: 80,
      pt: 2,
      pb: 2,
      pl: 2,
      pr: 2,
      mb: 4,
      backgroundColor: "#EAEAEA",
    }}
  >
    <Grid item xs={12} sm={6} md={6} lg={6}>
      <FormSelector service={service} setService={setService} />
    </Grid>

    <Grid
      item
      xs={12}
      sm={6}
      md={6}
      lg={6}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CustomSlider
        label="Width"
        value={componentWidth}
        compact={compact}
        onChange={(event, newValue) => {
          setComponentWidth(newValue);
          setDesktop(newValue > 540);
          if (compact && newValue > 540) {
            setCompact(false);
          }
        }}
      />
    </Grid>

    <Grid item xs={6} sm={3} md={3} lg={3} sx={{ml:1, mr: -1}}>
      <CustomToggle
        label="Compact"
        checked={compact}
        onChange={() => {
          if (!compact && componentWidth > 540) {
            setComponentWidth(540);
          }
          setCompact(!compact);
        }}
      />
    </Grid>

    <Grid item xs={6} sm={3} md={3} lg={3} sx={{ml:1, mr: -1}}>
      <CustomToggle
        label="MultiPart"
        checked={multiPart}
        onChange={() => setMultiPart(!multiPart)}
      />
    </Grid>

    <Grid item xs={6} sm={3} md={3} lg={3} sx={{ml:1, mr: -1}}>
      <CustomToggle
        label="Colour"
        checked={backgroundColor === "#F7ECF3"}
        onChange={() =>
          setBackgroundColor(backgroundColor === "#F7ECF3" ? "#FFF" : "#F7ECF3")
        }
      />
    </Grid>

    <Grid item xs={6} sm={3} md={3} lg={3} sx={{ml:1, mr: -1}}>
      <CustomToggle
        label="Padding"
        checked={containerPadding}
        onChange={() => setContainerPadding(!containerPadding)}
      />
    </Grid>
  </Grid>
);

ControlPanel.propTypes = {
  componentWidth: PropTypes.number.isRequired,
  setComponentWidth: PropTypes.func.isRequired,
  setDesktop: PropTypes.func.isRequired,
  compact: PropTypes.bool.isRequired,
  setCompact: PropTypes.func.isRequired,
  multiPart: PropTypes.bool.isRequired,
  setMultiPart: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  setBackgroundColor: PropTypes.func.isRequired,
  containerPadding: PropTypes.bool.isRequired,
  setContainerPadding: PropTypes.func.isRequired,
  service: PropTypes.string.isRequired,
  setService: PropTypes.func.isRequired,
};

export default ControlPanel;
