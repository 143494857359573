import React from 'react';
import PropTypes from "prop-types";

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const FormSelector = ({ service, setService }) => {

  const handleFormChange = (event) => {
    setService(event.target.value);
  }

  return (
    <TextField
      fullWidth
      select
      label="Select Form"
      value={service}
      onChange={handleFormChange}
      variant="outlined"
      color="primary"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        sx: {
          backgroundColor: 'white',
        },
      }}
    >
      <MenuItem value={'Snagging'}>Snagging</MenuItem>
      <MenuItem value={'SpecialistLending'}>Specialist Lending</MenuItem>
      <MenuItem value={'EPC'}>EPC</MenuItem>
      <MenuItem value={'RightToLight'}>Right To Light</MenuItem>
      <MenuItem value={'BuildingSurvey'}>Building Survey</MenuItem>
      <MenuItem value={'LeaseExpert'}>Lease Expert</MenuItem>
      <MenuItem value={'EA4Me'}>EA4Me</MenuItem>
      <MenuItem value={'BridgingLoanCalculator'}>Bridging Loan Calculator</MenuItem>
      <MenuItem value={'BestEstateAgentForm'}>Best Estate Agent Form</MenuItem>
      <MenuItem value={'HowMuchCanIBorrow'}>How Much Can I Borrow</MenuItem>
      <MenuItem value={'MortgageCost'}>Mortgage Cost</MenuItem>
    </TextField>
  );
}

export default FormSelector;

FormSelector.propTypes = {
    service: PropTypes.string.isRequired,
    setService: PropTypes.func.isRequired,
};
